<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <button class="btn-danger" @click="logout">Logout</button>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
  methods: {
    checkLogin() {
      if (
        this.$store.state.isLogin === false 
        &&
        localStorage.getItem("token") === null
      ) {
        this.$router.push("/login");
      } else {
        // TODO: Do some fetch?
        this.$router.push("/dashboard");
      }
    },
    logout() {
      localStorage.clear();
      this.$store.commit("LOGOUT");
      this.$router.push("/login");
    },
  },
  beforeMount() {
    this.checkLogin();
  },
};
</script>

<style lang="scss" scoped>
.home {
  align-content: center;
  height: 100vh;
  display: flex;

  button {
    margin: auto;
  }
}
</style>