const axios = require('axios');

const instance = axios.create({
  // baseURL: 'https://localhost:5301'
  // baseURL: 'https://dev-api.mountrash.co.id'
  // baseURL: 'https://dev-user.mountrash.online',
  baseURL: 'https://user.mountrash.online',
});

export default instance;
