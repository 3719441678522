import ordering from "../config/ordering";

const moduleKumham = {
  state: () => ({
    module: "Kementerian Hukum dan HAM",
    orderingResult: {},
  }),
  mutations: {
    FETCH_KUMHAM(state, payload) {
      state.orderingResult = payload;
    },
  },
  actions: {
    fetchKumham(context) {
      context.commit("SET_LOADING", true);
      return new Promise(function(resolve, reject) {
        ordering({
          method: "GET",
          url: "/kemenkumham/data",
        })
          .then(({ data }) => {
            context.commit("SET_LOADING", false);
            context.commit("FETCH_KUMHAM", data.data);
            resolve();
          })
          .catch((err) => {
            context.commit("SET_LOADING", false);
            reject(err.response.data);
          });
      });
    },
    addOrderId(context, payload) {
      context.commit("SET_LOADING", true);
      return new Promise(function(resolve, reject) {
        ordering({
          method: "POST",
          url: "/kemenkumham",
          headers: {
            access_token: localStorage.getItem("token"),
          },
          data: {
            OrderId: payload,
          },
        })
          .then(({ data }) => {
            console.log(data);
            if (data.status === 200) {
              return ordering({
                method: "GET",
                url: "/kemenkumham/data",
              });
            }
            reject(data);
          })
          .then(({ data }) => {
            context.commit("SET_LOADING", false);
            context.commit("FETCH_KUMHAM", data.data);
            resolve();
          })
          .catch((err) => {
            context.commit("SET_LOADING", false);
            console.log(err.response.data);
            reject(err.response.data);
          });
      });
    },
  },
};

export default moduleKumham;
