import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/dashboard/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "questions" */ '../components/PartnerDataTable.vue')
      },
      {
        path: 'usermtps',
        name: 'Mtps',
        component: () => import(/* webpackChunkName: "usermtps" */ '../components/UserDataTable.vue')
      },
      {
        path: 'mitra/:userId',
        name: 'Mitra',
        component: () => import(/* webpackChunkName: "questions" */ '../components/PartnerDetail.vue')
      },
      {
        path: 'user/:userId',
        name: 'User',
        component: () => import(/* webpackChunkName: "questions" */ '../components/UserDetail.vue')
      },
      {
        path: 'setsubscription/:userId',
        name: 'SetSubscription',
        component: () => import(/* webpackChunkName: "setsubscription" */ '../components/SetUserSubscription.vue')
      },
      {
        path: 'kemenkumham',
        name: 'Kemenkumham',
        component: () => import(/* webpackChunkName: "kemenkumham" */ '../views/kumham/Home.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
