import Vue from 'vue';
import Vuex from 'vuex';
import axios from '../config/api';
import wallet from '../config/wallet';
import moduleKumham from '../store/kumham'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false,
    isLoading: false,
    partners: [],
    users: [],
    userDetail: {},
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    LOGIN(state) {
      state.isLogin = true;
    },
    LOGOUT(state) {
      state.isLogin = false;
    },
    FETCH_PARTNERS(state, payload) {
      state.partners = [...payload];
    },
    FETCH_USERS(state, payload) {
      state.users = [...payload];
    },
    FETCH_USER(state, payload) {
      state.userDetail = payload;
    },
    FETCH_WALLET(state, payload) {
      state.userDetail = { ...state.userDetail, ...payload };
    },
  },
  actions: {
    login(context, payload) {
      return new Promise(function(resolve, reject) {
        context.commit('SET_LOADING', true);
        axios({
          method: 'POST',
          url: `/user/mountTps/login`,
          data: {
            emailOrPhoneNumber: payload.username,
            password: payload.password,
          },
        })
          .then(({ data }) => {
            context.commit('SET_LOADING', false);
            context.commit('LOGIN');
            localStorage.setItem('token', data.access_token);
            resolve();
          })
          .catch((err) => {
            context.commit('SET_LOADING', false);
            reject(err.response.data);
          });
      });
    },
    approveMTPS(context, payload) {
      return new Promise(function(resolve, reject) {
        context.commit('SET_LOADING', true);
        axios({
          method: 'POST',
          url: `/mitra`,
          headers: {
            access_token: localStorage.getItem('token'),
          },
          data: {
            role: 'mtpsverified',
            userId: payload.userId,
          },
        })
          .then(() => {
            context.commit('SET_LOADING', false);
            resolve();
          })
          .catch((err) => {
            context.commit('SET_LOADING', false);
            reject(err.response.data);
          });
      });
    },
    fetchPartners(context) {
      return new Promise(function(resolve, reject) {
        context.commit('SET_LOADING', true);
        axios({
          method: 'GET',
          url: '/mitra',
          headers: {
            access_token: localStorage.getItem('token'),
          },
        })
          .then(({ data }) => {
            context.commit('SET_LOADING', false);
            context.commit('FETCH_PARTNERS', data.data);
            resolve();
          })
          .catch((err) => {
            context.commit('SET_LOADING', false);
            reject(err.response.data);
          });
      });
    },
    fetchMTPSUsers(context) {
      return new Promise(function(resolve, reject) {
        context.commit('SET_LOADING', true);
        axios({
          method: 'GET',
          url: '/user/mountps',
          headers: {
            access_token: localStorage.getItem('token'),
          },
        })
          .then(({ data }) => {
            context.commit('SET_LOADING', false);
            context.commit('FETCH_USERS', data.data);
            resolve();
          })
          .catch((err) => {
            context.commit('SET_LOADING', false);
            reject(err.response.data);
          });
      });
    },
    fetchPartner(context, payload) {
      return new Promise(function(resolve, reject) {
        context.commit('SET_LOADING', true);
        axios({
          method: 'GET',
          url: `/mitra/userId/${payload.userId}`,
          headers: {
            access_token: localStorage.getItem('token'),
          },
        })
          .then(({ data }) => {
            context.commit('SET_LOADING', false);
            context.commit('FETCH_USER', data.data[0]);
            resolve();
          })
          .catch((err) => {
            context.commit('SET_LOADING', false);
            reject(err.response.data);
          });
      });
    },
    fetchUser(context, payload) {
      return new Promise(function(resolve, reject) {
        context.commit('SET_LOADING', true);
        axios({
          method: 'GET',
          url: `/user/mountps?UserId=${payload.userId}`,
          headers: {
            access_token: localStorage.getItem('token'),
          },
        })
          .then(({ data }) => {
            context.commit('FETCH_USER', data.data[0]);
            return wallet({
              method: 'GET',
              url: `/wallet/${payload.userId}`,
              headers: {
                access_token: localStorage.getItem('token'),
              },
            });
          })
          .then(({ data }) => {
            context.commit('SET_LOADING', false);
            context.commit('FETCH_WALLET', data.data);
            resolve();
          })
          .catch((err) => {
            context.commit('SET_LOADING', false);
            reject(err.response.data);
          });
      });
    },
  },

  modules: {
    moduleKumham
  },
});
